import React from 'react';
import Helmet from 'react-helmet';
import { Link as GatsbyLink } from 'gatsby';

import Section from '@nib-components/section';
import Heading from '@nib-components/heading';
import Copy from '@nib-components/copy';
import Link from '@nib-components/link';
import { Stack, Columns, Column } from '@nib/layout';

import Layout from '../../components/Layout';
import metrics from '../../metrics';

import robHennin from '../../img/directors/Rob Hennin.jpeg';
import anneLoveridge from '../../img/directors/Anne Loveridge.jpeg';
import hanneJanes from '../../img/directors/Hanne Janes.jpg';
import edClose from '../../img/directors/Ed Close.jpeg';
import markAue from '../../img/directors/Mark Aue.jpeg';
import JosephineGagan from '../../img/directors/Josephine Gagan.jpg';
import SophieHaslem from '../../img/directors/Sophie Haslem.jpg';
import { DirectorProfile } from '../../components/DirectorProfile';

const title = 'Directors';
const metaDescription = 'View the directors of nib New Zealand';

const DirectorsPage = () => (
  <Layout>
    <Helmet>
      <title>{title} | nib</title>
      <meta name="description" content={metaDescription} />
    </Helmet>
    <Section role="section">
      <Stack space={3}>
        <Link id="ga-inlink-static-site-page-9" component={GatsbyLink} to="/about-nib">
          &lt; Back
        </Link>
        <Heading size={1} color="trueGreen" align="center">
          nib Directors
        </Heading>
      </Stack>
    </Section>

    <Section>
      <Columns space={{ xs: 2, lg: 4 }} collapseBelow="md" align={{ xs: 'center', lg: 'left' }}>
        <Column>
          <Stack space={{ xs: 2, lg: 4 }}>
            <DirectorProfile profilePic={hanneJanes} heading="Hanne Janes" subHeading="Chairman">
              <Stack space={4}>
                <Heading size={3} color="trueGreen">
                  Chairman
                </Heading>
                <Copy measure={false}>
                  BA (University of Auckland), LLB (Hons) (University of Auckland)
                </Copy>
                <Copy measure={false}>Independent Non-Executive Director</Copy>
                <Copy measure={false}>
                  Hanne was appointed as Chairman of nib nz limited, nib nz insurance limited and
                  nib nz holdings limited in March 2024.
                </Copy>
                <Copy measure={false}>
                  She took her Chairman role after serving as an Independent Non-Executive Director
                  of nib nz limited since November 2016 and nib nz insurance limited since April
                  2022.
                </Copy>
                <Copy measure={false}>
                  For the past two decades Hanne has been a practicing barrister (specialising in
                  medico-legal, commercial and corporate law), and previously worked as a management
                  consultant with Deloitte.
                </Copy>
                <Copy measure={false}>
                  With more than 25 years’ specialist expertise in the healthcare sector, Hanne
                  brings a thorough understanding of the national health landscape, including
                  Government policy and funding, healthcare delivery, as well as regulatory and
                  compliance requirements. She has held appointments as Counsel Assisting the
                  Gisborne Cervical Screening Ministerial Inquiry, the Government Inquiry into
                  Mental Health and Addiction, and the Royal Commission of Inquiry into Abuse in
                  Care.
                </Copy>
                <Copy measure={false}>
                  She also has extensive experience in commercial and corporate law, together with a
                  detailed knowledge of workplace health and safety.
                </Copy>
                <Copy measure={false}>
                  Hanne has also served as a Non-Executive Director of ProCare Health Limited and
                  Member of the Board of Trustees of the ProCare Charitable Foundation.
                </Copy>
                <Copy measure={false}>
                  Hanne holds a Bachelor of Laws (Honours) and Bachelor of Arts (Economics and
                  Political Studies) from the University of Auckland.
                </Copy>
              </Stack>
            </DirectorProfile>
            <DirectorProfile
              profilePic={JosephineGagan}
              heading="Josephine Gagan"
              subHeading="Director"
            >
              <Stack space={4}>
                <Heading size={3} color="trueGreen">
                  Director
                </Heading>
                <Copy measure={false}>
                  Dip Health Mgmnt (University of Auckland); Regd Nurse (Leeds General Infirmary)
                </Copy>
                <Copy measure={false}>Independent Non-Executive Director</Copy>
                <Copy measure={false}>
                  Josephine was appointed as a Director of nib New Zealand in February 2024.
                </Copy>
                <Copy measure={false}>
                  Josephine is an experienced executive leader, Board Chair and Director. For more
                  than two decades she was the owner and Chief Executive of Geneva Healthcare,
                  providing aged care, illness, injury and disability support to New Zealanders.
                </Copy>
                <Copy measure={false}>
                  She brings to nib a thorough understanding of the healthcare sector, across home
                  and community services, rehabilitation, disability services, healthcare staffing
                  and health workforce recruitment. Her board work encompasses philanthropic and
                  research organisations, including Director of the Auckland Hospital Foundation,
                  and Chair of the Mind for Minds Trust, which researches autism spectrum disorder.
                </Copy>
                <Copy measure={false}>
                  Josephine is a qualified nurse (a registered nurse with Leeds General Infirmary in
                  the UK) and holds a Diploma in Health Management from the University of Auckland’s
                  Graduate School of Business.
                </Copy>
              </Stack>
            </DirectorProfile>
          </Stack>
        </Column>
        <Column>
          <Stack space={{ xs: 2, lg: 4 }}>
            <DirectorProfile
              profilePic={robHennin}
              heading="Rob Hennin"
              subHeading="Chief Executive Officer"
            >
              <Stack space={4}>
                <Heading size={3} color="trueGreen">
                  Chief Executive Officer
                </Heading>
                <Copy measure={false}>B.A.(University of Otago), GAICD</Copy>
                <Copy measure={false}>Executive Director</Copy>
                <Copy measure={false}>
                  Rob joined nib as Chief Executive Officer and a Director of nib nz limited in May
                  2013 and nib nz insurance limited in April 2022.
                </Copy>
                <Copy measure={false}>
                  Rob is the Chairman of nib Travel Pty as well as nib’s joint venture with Chinese
                  pharmaceutical company, Tasly and Chairman of Auoha Insurance. He is also an
                  Industry Representative for New Zealand’s Insurance and Financial Services
                  Ombudsman Commission.
                </Copy>
                <Copy measure={false}>
                  Rob has held several senior management and Executive-level positions with American
                  Express, Visa and Unilever - leading international teams to build global brands,
                  create innovative platforms and develop new products.
                </Copy>
                <Copy measure={false}>
                  Rob also has 10 years Non–Executive Director experience across a range of
                  industries, including health, education and humanitarian aid and acts as an
                  advisor on governance and strategy to not-for-profit organisations.
                </Copy>
              </Stack>
            </DirectorProfile>
            <DirectorProfile
              profilePic={edClose}
              heading="Ed Close"
              subHeading="Executive Director"
            >
              <Stack space={4}>
                <Heading size={3} color="trueGreen">
                  Executive Director
                </Heading>
                <Copy measure={false}>
                  LLB (University of Newcastle), BBus (Management) (University of Newcastle),
                  Diploma Legal Practice (College of Law, NSW), MBA(MQ) GAICD
                </Copy>

                <Copy measure={false}>
                  Ed Close was appointed as a Director of nib nz limited and nib nz insurance
                  limited in December 2024.
                </Copy>
                <Copy measure={false}>
                  Ed joined the nib Group in 2017 as Head of Marketing and Products for the
                  Australian Residents Health Insurance business. In January 2020, he was appointed
                  Chief Executive, Australian Residents Health Insurance (arhi). Ed was appointed
                  Managing Director and Chief Executive Officer of nib Group on 1 December 2024.
                </Copy>
                <Copy measure={false}>
                  Ed is a Director of nib holdings limited and nib health funds limited as well as
                  many other nib holdings limited subsidiaries.
                </Copy>
                <Copy measure={false}>
                  Ed has broad experience working in financial services and health insurance,
                  overseeing P&L, distribution, customer, digital and marketing functions alongside
                  motivating and leading high performing large and diverse teams. 
                </Copy>
                <Copy measure={false}>
                  Throughout his time at nib, Ed has had close oversight of nib’s payer to partner
                  strategy, health management programs, and partnerships including with med-tech
                  company Midnight Health, and our joint venture, Honeysuckle Health. Ed has broad
                  experience working in financial services and health insurance, overseeing the
                  end-to-end ownership of marketing and products, and motivating and leading high
                  performing teams. 
                </Copy>
              </Stack>
            </DirectorProfile>
            <DirectorProfile
              profilePic={SophieHaslem}
              heading="Sophie Haslem"
              subHeading="Director"
            >
              <Stack space={4}>
                <Heading size={3} color="trueGreen">
                  Director
                </Heading>
                <Copy measure={false}>
                  BCom (University of Melbourne); Grad Dip Mngmt (University of Melbourne)
                </Copy>
                <Copy measure={false}>
                  Sophie was appointed as a Director of nib New Zealand in February 2024. Sophie is
                  Chair of nib nz holdings limited's Audit Committee and nib nz limited's Board,
                  Audit, Risk and Compliance Committee (BARCC).
                </Copy>
                <Copy measure={false}>
                  She has more than 25-years of broad commercial experience working across large
                  corporations and early-stage growth companies. She has worked in corporate and
                  investment banking, management consulting and spent more than a decade at New
                  Zealand Post Ltd in key executive roles. Her experience includes governance,
                  extensive M&A, business strategy and technology.
                </Copy>
                <Copy measure={false}>
                  Sophie is current Chair at Kordia Group Limited and is Deputy Chair at CentrePort
                  Limited. She is also on the Board of Rangatira Limited, Payments New Zealand
                  Limited, and Livestock Improvement Corporation. She was formerly on the Board at
                  Ngāi Tahi Holdings Corporation, an investment company managing more than $2
                  billion in tribal assets.
                </Copy>
                <Copy measure={false}>
                  Sophie has a Bachelor of Commerce and Post-Graduate Diploma in Management from the
                  University of Melbourne.
                </Copy>
              </Stack>
            </DirectorProfile>
          </Stack>
        </Column>

        <Column>
          <Stack space={{ xs: 2, lg: 4 }}>
            <DirectorProfile
              profilePic={anneLoveridge}
              heading="Anne Loveridge"
              subHeading="Director"
            >
              <Stack space={4}>
                <Heading size={3} color="trueGreen">
                  Director
                </Heading>
                <Copy measure={false}>BA (University of Reading), FCA, GAICD</Copy>
                <Copy measure={false}>Non-Executive Director</Copy>
                <Copy measure={false}>
                  Anne was appointed to the Board of nib holdings limited in February 2017 and has
                  been a Director of nib nz limited since October 2017. She is also a Director of
                  nib health funds limited and nib nz insurance limited.
                </Copy>
                <Copy measure={false}>
                  Anne has over 35 years’ experience in banking, wealth management, private equity
                  and property. She has extensive knowledge of financial and regulatory reporting,
                  risk management controls and compliance frameworks.
                </Copy>
                <Copy measure={false}>
                  Anne is Chair of the nib holdings limited Audit Committee and member of the Risk
                  and Reputation Committee, and Nomination Committee.
                </Copy>
                <Copy measure={false}>
                  She is currently a Non-Executive Director of Platinum Asset Management Limited,
                  Accent Group Limited, HSBC Bank Australia Limited and Destination NSW.
                </Copy>
                <Copy measure={false}>
                  Formally trained as a Chartered Accountant, Anne has a breadth of experience in
                  financial reporting, auditing, risk, ethics and regulatory affairs following her
                  31 years with PriceWaterhouseCoopers (PwC) Australia, where she retired as Partner
                  and Deputy Chair in 2015. Anne is also a Fellow of the Chartered Accountants
                  Australia and New Zealand (FCA).
                </Copy>
              </Stack>
            </DirectorProfile>
            <DirectorProfile profilePic={markAue} heading="Mark Aue" subHeading="Director">
              <Stack space={4}>
                <Heading size={3} color="trueGreen">
                  Director
                </Heading>
                <Copy measure={false}>Independent Non-Executive Director</Copy>
                <Copy measure={false}>
                  Mark was appointed as Director of nib nz limited in February 2023. He is an
                  experienced leader, with strong commercial skills. He brings a breadth of
                  experience to nib nz from telecommunications, digital technology and professional
                  services. He is the Chief Financial Officer and Chief Operating Officer for NZX50
                  listed telecommunications infrastructure company Chorus.
                </Copy>
                <Copy measure={false}>
                  Mark has worked in the telecommunications industry for over 20 years, and has held
                  senior positions in NZ, Australia, and the UK. Most recently he was the Chief
                  Executive for 2Degrees and led the dual listing IPO process (NZX/ASX) and
                  subsequent trade sale to Macquarie Asset Management.
                </Copy>
                <Copy measure={false}>
                  Before 2Degrees he held senior roles at Vodafone, including as Chief Financial
                  Officer of the New Zealand business. Mark was also the Chief Financial Officer of
                  the Vodafone Global Enterprise business based in the UK that spanned operations in
                  25 countries. Mark has a personal passion for developing NZ Inc. He recognises the
                  role technology plays as an enabler and in parallel the opportunity to drive
                  positive societal outcomes to make NZ a better place to live.
                </Copy>
              </Stack>
            </DirectorProfile>
          </Stack>
        </Column>
      </Columns>
    </Section>
  </Layout>
);

export default metrics({ pageName: 'directors' })(DirectorsPage);
